@tailwind base;
@tailwind components;
@tailwind utilities;

.active .nav-text {
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity));
}

.active .nav-indicator {
    --tw-text-opacity: 1;
    background-color: rgb(226 232 240 / var(--tw-text-opacity));
}


.experience #download_link {
  position: relative;
  text-transform: uppercase;
  overflow: hidden;
  transition: .25s;
  letter-spacing: 1px;
  border-width: 0.5px;
}

.experience #download_link:hover {
  box-shadow: 0 0 2.5px rgb(153, 246, 228),
              0 0 12.5px rgb(153, 246, 228),
              0 0 25px rgb(153, 246, 228),
              0 0 50px rgb(153, 246, 228);
}

.experience #download_link span {
  position: absolute;
  display: block;
}

.experience #download_link span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, rgb(153, 246, 228));
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,100% {
    left: 100%;
  }
}

.experience #download_link span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, rgb(153, 246, 228));
  animation: btn-anim2 1s linear infinite;
  animation-delay: .25s
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,100% {
    top: 100%;
  }
}

.experience #download_link span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, rgb(153, 246, 228));
  animation: btn-anim3 1s linear infinite;
  animation-delay: .5s
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,100% {
    right: 100%;
  }
}

.experience #download_link span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, rgb(153, 246, 228));
  animation: btn-anim4 1s linear infinite;
  animation-delay: .75s
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,100% {
    bottom: 100%;
  }
}
